html,
body {
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  max-width: 100%;
  /* top: 0 !important; */
}

/* body .skiptranslate:first-of-type {
   display: none;
} */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  height: 7rem;
  background: rgb(21, 21, 23);
  background: linear-gradient(
    180deg,
    rgba(21, 21, 23, 0.6867121848739496) 0%,
    rgba(9, 9, 121, 0) 75%,
    rgba(0, 212, 255, 0) 100%
  );
}

@media (max-width: 740px) {
  .header {
    justify-content: center;
    flex-direction: column;
  } 
}

svg {
  width: 22px;
}

ul {
  list-style: none;
  padding: 09;
}

.wrapper {
  max-width: 65rem;
  margin: 0 auto;
  padding: 0 1.2rem;
}

@media (min-width: 65rem) {
  .wrapper {
    padding: 0;
  }
}
/* 
.skiptranslate {
  position: relative;
  bottom: 2.4px;
  height: 2rem;
  width: 7.4rem;
  overflow: hidden;
}

.goog-te-combo {
  padding: .3rem;
  border-radius: 4px;
}  */
